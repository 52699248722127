// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-theme-templates-main-index-jsx": () => import("./../../../src/theme/templates/main/index.jsx" /* webpackChunkName: "component---src-theme-templates-main-index-jsx" */),
  "component---src-theme-templates-page-index-jsx": () => import("./../../../src/theme/templates/page/index.jsx" /* webpackChunkName: "component---src-theme-templates-page-index-jsx" */),
  "component---src-theme-templates-product-index-jsx": () => import("./../../../src/theme/templates/product/index.jsx" /* webpackChunkName: "component---src-theme-templates-product-index-jsx" */)
}

